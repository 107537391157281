export const ExposureS = () => (
	<svg
		id='Layer_1'
		data-name='Layer 1'
		xmlns='http://www.w3.org/2000/svg'
		width='90'
		height='32'
		viewBox='0 0 90 32'
	>
		<defs>
			<style></style>
		</defs>
		<g>
			<line
				className='cls-1'
				x1='5.76'
				y1='16.23'
				x2='84'
				y2='16.23'
				fill='none'
				stroke='#000'
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='2px'
			/>
			<g>
				<path
					className='cls-2'
					d='M18.7,20.56A4.34,4.34,0,1,1,23,16.23,4.33,4.33,0,0,1,18.7,20.56Z'
					fill='#00CF00'
				/>
				<path d='M18.7,12.39a3.84,3.84,0,1,1-3.83,3.84,3.84,3.84,0,0,1,3.83-3.84m0-1a4.84,4.84,0,1,0,4.84,4.84,4.83,4.83,0,0,0-4.84-4.84Z' />
			</g>
			<g>
				<path
					className='cls-3'
					d='M33.08,17.76a1.58,1.58,0,1,1,1.58-1.58A1.58,1.58,0,0,1,33.08,17.76Z'
					fill='#FFFFFF'
				/>
				<path d='M33.08,15.1A1.09,1.09,0,1,1,32,16.18a1.09,1.09,0,0,1,1.08-1.08m0-1a2.09,2.09,0,1,0,2.08,2.08,2.08,2.08,0,0,0-2.08-2.08Z' />
			</g>
			<g>
				<path
					className='cls-3'
					d='M46.59,17.76a1.58,1.58,0,1,1,1.59-1.58A1.58,1.58,0,0,1,46.59,17.76Z'
				/>
				<path d='M46.59,15.1a1.09,1.09,0,1,1-1.08,1.08,1.09,1.09,0,0,1,1.08-1.08m0-1a2.09,2.09,0,1,0,2.09,2.08,2.08,2.08,0,0,0-2.09-2.08Z' />
			</g>
			<g>
				<path
					className='cls-3'
					d='M60.24,17.81a1.59,1.59,0,1,1,1.58-1.58A1.58,1.58,0,0,1,60.24,17.81Z'
				/>
				<path d='M60.24,15.14a1.09,1.09,0,1,1-1.08,1.09,1.08,1.08,0,0,1,1.08-1.09m0-1a2.09,2.09,0,1,0,2.08,2.09,2.09,2.09,0,0,0-2.08-2.09Z' />
			</g>
			<g>
				<path
					className='cls-3'
					d='M73.63,17.81a1.59,1.59,0,1,1,1.58-1.58A1.58,1.58,0,0,1,73.63,17.81Z'
				/>
				<path d='M73.63,15.14a1.09,1.09,0,1,1-1.08,1.09,1.08,1.08,0,0,1,1.08-1.09m0-1a2.09,2.09,0,1,0,2.08,2.09,2.09,2.09,0,0,0-2.08-2.09Z' />
			</g>
			<text
				className='cls-4'
				fontSize='4.3px'
				fontFamily='HelveticaNeue-Bold, Helvetica Neue'
				fontWeight='700'
				transform='translate(16.11 9.67)'
			>
				E1
			</text>
		</g>
	</svg>
)
