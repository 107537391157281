import React from 'react'

export const LargeBikerIcon = ({ size = 100, color = '#000000', className }) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 100 100'
		className={className}
		fill={color}
	>
		<path d='M67.61,40.22l.84-.03c.54-.2,1.09-.05,1.24,.34l9.43,26.82c.14,.39-.18,.86-.72,1.06h0c-.54,.2-1.09,.05-1.24-.34l-9.43-26.82c-.14-.39-.66-.83-.12-1.03Z' />
		<path d='M50.16,26.43c1.29,6.51,5.09,16.24,6.74,15.98,2.03-.31,.23-13.22-1.36-16.82-1.3-2.94-4.42-3.07-5.39,.83Z' />
		<path d='M56.45,42.33c1.18,.57,13-.07,13.23-1.73,.28-2.03-11.66-2.01-13.09-1.47-1.57,.59-1.22,2.47-.14,3.2Z' />
		<circle
			cx='62.61'
			cy='20.59'
			r='6.49'
		/>
		<path d='M51.67,22.63c-6.48-.45-22.03,6.65-21.05,10.04,.72,3.07,20.16-.09,23.16-2.36,2.84-2.15,2.61-6.79-2.1-7.68Z' />
		<path d='M26.78,38.61c2.25,4.12,16.58,14.64,17.82,12.44,1.73-2.77-7.54-15.2-13.68-16.64-4.06-.95-5.29,2.04-4.14,4.2Z' />
		<path d='M41.2,49.24c-2.65,2.08-13.6,14.57-12.04,15.69,1.59,.99,15.33-11.25,15.64-13.92,.19-1.65-2.37-2.53-3.59-1.77Z' />
		<path d='M77.6,86.28c-10.43,0-18.92-8.49-18.92-18.92s8.49-18.92,18.92-18.92,18.92,8.49,18.92,18.92-8.49,18.92-18.92,18.92Zm0-35.27c-9.02,0-16.35,7.34-16.35,16.35s7.34,16.35,16.35,16.35,16.35-7.34,16.35-16.35-7.34-16.35-16.35-16.35Z' />
		<path d='M23.16,86.28c-10.43,0-18.92-8.49-18.92-18.92s8.49-18.92,18.92-18.92,18.92,8.49,18.92,18.92-8.49,18.92-18.92,18.92Zm0-35.27c-9.02,0-16.35,7.34-16.35,16.35s7.34,16.35,16.35,16.35,16.35-7.34,16.35-16.35-7.34-16.35-16.35-16.35Z' />
		<path d='M68.46,40.48l.61,.57c.52,.24,.81,.74,.64,1.11l-16.99,25.79c-.17,.37-.74,.48-1.26,.24h0c-.52-.24-.81-.74-.64-1.11l16.99-25.79c.17-.37,.12-1.05,.65-.81Z' />
		<path d='M53.08,66.56l-.26,.8c.01,.58-.32,1.05-.75,1.06l-29.25,.09c-.42,0-.78-.45-.79-1.03h0c-.01-.58,.32-1.05,.75-1.06l29.25-.09c.42,0,1.03-.35,1.04,.23Z' />
	</svg>
)
