export const AngleIcon = ({ size = 32 }) => (
	<svg
		className='angle-icon'
		width={size}
		height={size}
		viewBox='0 0 32 32'
	>
		<g>
			<path d='M16.28,25.26h-.09a.92.92,0,0,1-.83-1c0-.06.5-6-3.69-9a.93.93,0,0,1,1.1-1.5c5.05,3.7,4.46,10.43,4.43,10.71A.92.92,0,0,1,16.28,25.26Z' />
			<path d='M31.08,25.26H.94a.93.93,0,0,1-.61-1.63L22.89,3.89A.93.93,0,0,1,24.2,4a.92.92,0,0,1-.09,1.31L3.41,23.4H31.08a.93.93,0,0,1,0,1.86Z' />
		</g>
	</svg>
)

export const ElevationIcon = ({ size = 20 }) => (
	<svg
		className='elevation-icon'
		width={size}
		height={size}
		viewBox='0 0 20 20'
	>
		<path d='M19.93,17.84,10.45,1.41a.54.54,0,0,0-.9,0L.07,17.84a.49.49,0,0,0,0,.52.51.51,0,0,0,.44.26h19a.51.51,0,0,0,.45-.26A.53.53,0,0,0,19.93,17.84Zm-4.08-5H4.15L6.35,9h7.3ZM10,2.71,13.05,8H7ZM1.41,17.58l2.14-3.7h12.9l2.14,3.7Z' />
	</svg>
)

export const DistanceIcon = ({ size = 20 }) => (
	<svg
		width={size}
		height={size}
		className='distance-icon'
		viewBox='0 0 20 20'
	>
		<path d='M19.47,4.81H.52A.52.52,0,0,0,0,5.33v9.48a.52.52,0,0,0,.53.52h19a.52.52,0,0,0,.52-.52V5.33A.52.52,0,0,0,19.47,4.81Zm-.53,9.47H16.57V11.65a.53.53,0,0,0-.53-.53.52.52,0,0,0-.52.53v2.63H13.41V12.44a.52.52,0,0,0-.52-.53.53.53,0,0,0-.53.53v1.84H10.25V10.07a.52.52,0,0,0-.52-.53.53.53,0,0,0-.53.53v4.21H7.1V12.44a.53.53,0,0,0-1.06,0v1.84H3.94V11.65a.53.53,0,0,0-.53-.53.52.52,0,0,0-.52.53v2.63H1V5.86h17.9Z' />
	</svg>
)
