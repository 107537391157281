import React from 'react'

export const LargeTourerIcon = ({ size = 100, className, color = '#000000' }) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 100 100'
		fill={color}
		className={className}
	>
		<circle
			cx='50.57'
			cy='8.88'
			r='7.49'
		/>
		<path d='M42.65,19.55c-3.72,6.49-4.15,26.2-.14,26.89,3.54,.79,10.02-20.96,9.17-25.22-.81-4.03-5.74-6.11-9.03-1.67Z' />
		<path d='M41.9,46.66c3.35,4.24,21.68,13.41,22.67,10.67,1.42-3.49-11.52-15.81-18.77-16.25-4.8-.29-5.61,3.34-3.89,5.57Z' />
		<path d='M59.62,56.29c-.13,3.88,2.81,22.81,4.95,22.25,2.06-.67,1.41-21.88-.73-24.12-1.32-1.39-3.99,.22-4.22,1.87Z' />
		<path d='M44.69,22.4c3.65,8.51,15.83,14.23,17.45,12.89,1.98-1.63-8.19-14.37-12.19-17.23-3.27-2.33-6.7-.48-5.26,4.33Z' />
		<path d='M60.82,35.39c1.53,.58,16.18-1.46,16.3-3.56,.14-2.57-14.73-1.27-16.46-.45-1.89,.9-1.25,3.21,.16,4Z' />
		<path d='M91.25,69.28s-3.19,3.81-3.69,3.95S7.28,95.44,7.28,95.44c-.86,.23-1.37,1.11-1.14,1.97s1.11,1.37,1.97,1.14l80.35-22.23c.31-.12,1.09-.52,1.54-1.05l3.52-4.03c.58-.67,.51-1.69-.17-2.27-.67-.58-1.53-.36-2.11,.31Z' />
		<path d='M75.66,30.92h0c.75-.08,1.42,.37,1.49,1.02l5.05,45.03c.07,.65-.48,1.24-1.23,1.32h0c-.75,.08-1.42-.37-1.49-1.02l-5.05-45.03c-.07-.65,.48-1.24,1.23-1.32Z' />
		<path d='M38.09,34.59s-5.98-2.16-6.21-4.51c-.29-2.95,1.04-7.78,2.41-9.85,1.52-2.28,6.78-.98,6.78-.98,0,0-1.61,3.99-2.25,7.44-.72,3.9-.74,7.91-.74,7.91Z' />
		<path d='M37.78,49.46c-5.97,9.72-10.39,37.71-8.72,38.57,2.37,.64,15.12-29.89,15.15-36.58,.01-3.53-4.26-4.6-6.43-2Z' />
	</svg>
)
