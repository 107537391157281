export const buttonText = {
	heroButtonText: 'Get Started',
	login: 'Log In',
	signup: 'Create an Account',
	addAdventure: 'Add New Adventure',
	cancel: 'Cancel',
	previewSave: 'Preview Save',
	finishSave: 'Finish Saving',
	editAdventure: 'Edit Adventure',
	deleteAdventureVar: 'Delete {var}',
	addToTicklist: 'Add to Todo List',
	completeActivity: 'Complete Adventure',
	deleteAdventure: 'Delete Adventure',
	profile: 'Profile',
	adventures: 'Adventures',
	saveUser: 'Save User',
	editUser: 'Edit User',
	logout: 'Logout',
	follow: 'Friend {var}',
	message: 'Message {var}',
	show: 'Show',
	hide: 'Hide',
	share: 'Share'
}
